export default {
  links: {
    services: 'Сервисы',
    autobot: 'Автоответы отзывы',
    analyser: 'Аналитика',
    aiReviewResponeGenerator: 'Онлайн генератор ответа',
    advantages: 'Цены',
    news: 'Новости',
    blog: 'Блог',
    contacts: 'Контакты',
    help: '+7 952 217 06 05',
    telegramBot: 'Телеграм-бот',
    crm: 'CRM Система',
    questions: 'Автоответы вопросы',
  },
  signIn: {
    title: 'Войти в SalesArea',
    text1: 'Введите вашу электронную почту и пароль,',
    text2: 'чтобы начать работу с сервисом.',
    text3: 'Мы отправили пароль для входа',
    text4: 'на вашу электронную почту.',
    signUpBtn: 'Зарегистрироваться',
  },
  signUpSuccess: {
    titleSuccess: 'Вы успешно зарегистрированы!',
  },
  restorePass: {
    titleSuccess: 'Пароль восстановлен!',
  },
  forgotPass: {
    title: 'Восстановление пароля',
    text1: 'Сообщение с новым паролем будет отправлено на',
    text2: 'указанную электронную почту.',
    sendBtn: 'Отправить',
    forgotPassBtn: 'Забыл пароль',
  },
  enter: 'Войти',
  signUp: 'Регистрация',
  makeOrder: 'Оставить заявку',
  detailed: 'Подробнее',
  privacy: 'Я предоставляю согласие на обработку персональных данных',
  advertising: 'Я согласен получать новости и рекламные рассылки',
  allNews: 'Все новости',
  email: 'Электронная почта',
  password: 'Пароль',
  phone: 'Телефон',
  telegram: 'Телеграм',
  name: 'Имя',
  companyName: 'Компания',
  siteName: 'Название сайта SalesArea',
  message: 'Сообщение',
  rememberMe: 'Запомнить меня',
  answer: 'Ответить',
  send: 'Отправить',
  confidencePolicy: 'Политика конфиденциальности',
  userAgreement: 'Пользовательское соглашение',
};
